import { useState } from "react"
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import ListGroup from 'react-bootstrap/ListGroup';
import '../css/home.css'

const Home = () => {

    return (

        <div id='homediv' className="container px-0">
            <div className="container px-3 shadow-lg rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-3 psyd-accent-bg-color border border-3 p-3 psyd-font-accent">
                        AI Powered Therapy
                    </div>
                    <div className="col">
                    </div>
                    <div className="col">
                    </div>
                </div>
                <div className="row px-1 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg">
                    <div className="col p-1">
                    Welcome to PSYD, our online therapy platform, where you can access support and guidance through an AI-powered therapist via a convenient chat interface. Our platform is designed to provide you with a flexible and accessible way to engage in therapy at your own pace. PSYD, powered by advanced artificial intelligence and language models, offers personalized online therapy sessions. Whether you're dealing with stress, anxiety, or other mental health challenges, this AI-powered therapist can provide guidance, coping strategies, and a safe space to explore your thoughts and feelings. PSYD's mission is to make Mental Health Support available to Anyone, Anywhere, Anytime!
                    </div>
                </div>
            </div>
            <div className="container px-3 psyd-font shadow-lg rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-3 psyd-accent-bg-color border border-3 p-3 psyd-font-accent">
                        Advantages
                    </div>
                    <div className="col">

                    </div>
                    <div className="col">
                    </div>
                </div>
            </div>
            <div className="container  mt-0 px-1 psyd-font ">
                <div class="row mt-0">
                    <div class="col-md-6">
                        <div className="p-1 psyd-secondary-bg-color psyd-font shadow-lg">
                            <ListGroup className="shadow-lg rounded p-0">
                                <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1 bi bi-check-circle">&nbsp;<b>24/7 Availability</b></ListGroup.Item>
                                <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1 bi bi-check-circle">&nbsp;<b>Personalized Therapy</b></ListGroup.Item>
                                <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1 bi bi-check-circle">&nbsp;<b>Private and Unbiased</b></ListGroup.Item>
                                <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1 bi bi-check-circle">&nbsp;<b>Flexible Session Options</b></ListGroup.Item>
                                <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1 bi bi-check-circle">&nbsp;<b>Automated Progress Tracking*</b></ListGroup.Item>
                                <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1 bi bi-check-circle">&nbsp;<b>Therapy Session Summaries*</b></ListGroup.Item>
                                <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1 bi bi-check-circle">&nbsp;<b>Goal Setting and Monitoring*</b></ListGroup.Item>
                                <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1 bi bi-check-circle">&nbsp;<b>Integration with Human Therapists*</b></ListGroup.Item>
                            </ListGroup>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div className="text-center psyd-secondary-bg-color psyd-font shadow-lg rounded d-none d-md-block d-xl-block">
                            <img
                                className="" src="../img/OnlineTherapy.jpg"
                                alt="psyd chat"
                                style={{ width: '600px', height: '350px' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <Link to={'/sessions'} style={{ display: 'flex', justifyContent: 'center' }}>
                <Button>Start a Session</Button>
            </Link>
        </div>
    )
}

export default Home


