// Header.js
import { useState } from "react"
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useEffect } from 'react';
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';


function Header() {
    const [loggedinuser, setloggedInUser] = useState('');

    const userCookie = Cookies.get('userinfo');

    let userinfo = {};
    if (userCookie) {
        userinfo = JSON.parse(userCookie);
    }
    useEffect(() => {
        let user = userinfo.preferred_name;
        if (user) {
            setloggedInUser(user);
        } else {
            user = 'Guest';
            setloggedInUser(user);
        }
    }, '');

    return (
        <div className="container text-center psyd-primary-bg-color psyd-font-light">
    <div id="homediv" className="container px-0">
        <div className="container px-0 psyd-primary-bg-color psyd-font">
            <div className="row mt-0 px-0">
                <div className="col-sm-2 d-none d-md-block align-left justify-content-start">
                    {/* Optional: Consider adding content here or remove the column */}
                </div>
                <div className="col-sm-8 psyd-font-light">
                    <h3>Personalized Therapy</h3>
                    <h6>Anyone, Anywhere, Anytime</h6>
                    <h6>Hello, {loggedinuser}</h6>
                </div>
                <div className="col-sm-2 p-2">
                    <Link to="/sessions">
                        <Button>Start a Session</Button>
                    </Link>
                </div>
            </div>
        </div>
    </div>
</div>

    );
}

export default Header
