import React from 'react';
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Cookies from 'js-cookie';

const Profile = ({ changeMenuOption }) => {
  const handleLogout = () => {
    const useridcookieExists = Cookies.get('userid') !== undefined;
    if (useridcookieExists) {
      Cookies.remove('userid'); // Use string literal for cookie name
    }
    
    const userinfocookieExists = Cookies.get('userinfo') !== undefined;
    if (userinfocookieExists) {
      Cookies.remove('userinfo'); // Use string literal for cookie name
    }
    changeMenuOption('login', 'Login', '/login');
  };

  return (
    <div id='homediv' className="container px-0">
      <div className="container px-0 psyd-secondary-bg-color psyd-font shadow-lg rounded">
        <div className="row mt-1">
          <div className="col p-3">
            Profile update options coming soon.
          </div>
          <br />
          <Link to='/' style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleLogout}>Logout</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Profile;
