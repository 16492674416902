import React from "react"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../css/navbar-main.css'
import { Link } from "react-router-dom"

const NavbarMain = ({menuOptions}) => {
    return (
        <Navbar menuOptions={menuOptions} expand="lg" sticky="top" className="px-0">
            <Container>
                <Navbar.Brand href="/">
                <img className="align-left" src="../img/logo.png" alt="psyd chat" width="100px" height="100px"/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto navbar-right">
                        <Nav.Link href={menuOptions.home.link}>{menuOptions.home.name}</Nav.Link> 
                        <Nav.Link href={menuOptions.process.link}>{menuOptions.process.name}</Nav.Link> 
                        <Nav.Link href={menuOptions.plans.link}>{menuOptions.plans.name}</Nav.Link> 
                        <Nav.Link href={menuOptions.themes.link}>{menuOptions.themes.name}</Nav.Link> 
                        <Nav.Link href={menuOptions.about.link}>{menuOptions.about.name}</Nav.Link> 
                        <Nav.Link href={menuOptions.login.link}>{menuOptions.login.name}</Nav.Link> 
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavbarMain
