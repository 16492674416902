import React, { useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';
import { Link } from "react-router-dom"
import { Button } from "react-bootstrap"



const Process = () => {
  const location = useLocation();

  return (
    <div id='homediv' className="container px-0">
      <div className="container px-3 shadow-lg rounded">
        <div className="row mt-2 mb-0 rounded">
          <div className="col-md-12 psyd-accent-bg-color border border-3 p-3 psyd-font-accent">
            How it Works?
          </div>
          <div className="col">
          </div>
          <div className="col">
          </div>
        </div>
      </div>

      <div className="container px-3">
        <div className="row mt-1">
          <div className="col p-3 mb-2 psyd-secondary-bg-color psyd-font shadow-lg rounded">
            You can begin your journey with us by choosing a Guest session, which allows you to explore some basic features without the need for an account. This option is perfect for those who want to experience the service before committing to a full account.
          </div>
        </div><div className="row mt-1">
          <div className="col p-3 mb-2 psyd-secondary-bg-color psyd-font shadow-lg rounded">
            If you decide to create a free account using your Google account, you unlock a range of enhanced features that significantly enrich your therapy experience. With an account, you’ll have the ability to review or continue past sessions ensuring you can pick up right where you left off. This continuity is crucial as it allows for a deeper exploration of your thoughts and feelings over time. Additionally, having an account provides a secure space to track your progress, reflect on previous conversations, and build on your therapeutic journey.
          </div>
        </div><div className="row mt-1">
          <div className="col p-3 psyd-secondary-bg-color psyd-font shadow-lg rounded mb-5">
            Whether you're looking for coping strategies, stress relief, or simply someone to talk to, our platform is here to help you every step of the way. Start your journey with us today, and discover the benefits of accessible, AI-powered therapy at your fingertips.
          </div>
          <br />
          <Link to={'/sessions'} style={{ display: 'flex', justifyContent: 'center' }}>
            <Button>Start a Session</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Process;
