import React, { useEffect } from 'react';
import { useLocation, Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const ProcessLogin = ({ changeMenuOption }) => {
  const location = useLocation();

  useEffect(() => {
    changeMenuOption('login', 'Profile', '/profile');
  }, [changeMenuOption]); // Ensure it runs once on mount

  return (
    <div id='homediv' className="container px-0">
      <div className="container px-0 psyd-secondary-bg-color psyd-font shadow-lg rounded">
        <div className="row mt-1">
          <div className="col p-3">
            Your login was processed successfully. Please start a new session.
          </div>
          <br />
          <Link to='/sessions' style={{ display: 'flex', justifyContent: 'center' }}>
            <Button>Start a Session</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default ProcessLogin;
