// src/therapy.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';

// Set axios default settings to include cookies
axios.defaults.withCredentials = true;

function Messages() {
    const [messages, setMessages] = useState([]);
    const [newClientInput, setNewClientInput] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const scrollRef = useRef(null);

    const fetchMessages = async () => {
        try {
            const response = await axios.get('/api/therapy');
            const tempmessages = response.data.therapyhistory;
            const parsedMessages = tempmessages?.map(tempmessage => JSON.parse(tempmessage)) || [];
            setMessages(parsedMessages);
            scrollToBottom(); // Scroll to the last message after fetching
        } catch (error) {
            console.error('Error fetching messages', error);
        }
    };

    const handleSendInput = async () => {
        if (newClientInput.trim() === '') return;
        setIsProcessing(true); // Disable button and show processing state
        try {
            const res = await axios.post('/api/therapy', { content: JSON.stringify({ "clientinput": newClientInput }) });
            const sessionid = res.data.sessionid;
            Cookie.set('sessionid', sessionid);  // Store sessionid in cookies
            setNewClientInput('');
            await fetchMessages(); // Wait for messages to fetch
        } catch (error) {
            console.error('Error sending input', error);
        } finally {
            setIsProcessing(false); // Re-enable button
        }
    };

    const scrollToBottom = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        const cookieExists = Cookies.get('sessionid') !== undefined;
        if (cookieExists) {
            fetchMessages();
        }
    }, []);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission on Enter key press
        }
    };

    return (
        <div>
            <div className="container mt-4">
                <div className="container px-3 shadow-lg rounded">
                    <div className="row mt-2 mb-2 rounded">
                        <div className="col-md-4 psyd-accent-bg-color border border-3 p-3 psyd-font-accent">
                            My Session
                        </div>
                        <div className="col"></div>
                        <div className="col"></div>
                    </div>
                </div>
                <div className="container px-3 shadow-lg rounded">
                    <div className="row">
                        <div className="col-md-8 px-0 mb-3"> {/* Added margin-bottom to create space between columns */}
                            <div className="border border-3 rounded-top psyd-primary-bg-color psyd-font-light text-center">
                                Session with PSYD
                            </div>
                            <div
                                className="px-1 mb-0 border border-top-0 border-3 rounded-left rounded-right"
                                style={{ height: '400px', overflowY: 'scroll', scrollbarWidth: 'thin', borderColor: 'white' }} // Change border color to white
                                ref={scrollRef}
                            >
                                <ul className="mx-0 px-0">
                                    {messages.length === 0 && <p className="text-center text-muted">No messages yet</p>}
                                    {messages?.map((record) => (
                                        <li className="psyd-chat" key={record.history_id}> {/* Added key to list item */}
                                            <div className="d-flex justify-content-end mb-2 mt-2">
                                                <div className="p-1 rounded psyd-chat-input text-right" style={{ maxWidth: '70%' }}>
                                                    {record.input_text}
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-start">
                                                <div className="p-1 rounded psyd-chat-response" style={{ maxWidth: '70%' }}>
                                                    {record.response_text}
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <Form className="mt-3 mb-2">
                                <Form.Group className="d-flex">
                                    <Form.Control
                                        type="text"
                                        value={newClientInput}
                                        onChange={(e) => setNewClientInput(e.target.value)}
                                        onKeyDown={handleKeyDown} // Prevent form submission on Enter key press
                                        placeholder="Type your message..."
                                    />
                                    <Button
                                        variant="primary"
                                        onClick={handleSendInput}
                                        className="ms-2"
                                        disabled={isProcessing} // Disable button while processing
                                    >
                                        {isProcessing ? 'Processing...' : 'Send'} {/* Change button text */}
                                    </Button>
                                </Form.Group>
                            </Form>
                        </div>
                        <div className="col-md-4 px-2">
                            <div>
                                <div className="border border-3 rounded-top psyd-primary-bg-color psyd-font-light text-center">
                                    Tips for Therapy
                                </div>
                                <div className="px-1 border border-top-0 border-3 rounded-left rounded-right">
                                    <ListGroup className="shadow-lg rounded p-1">
                                        <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1 mb-1 bi bi-check-circle">
                                            <b>&nbsp;Be Open and Honest</b>
                                            </ListGroup.Item>
                                        <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1 mb-1 bi-check-circle">
                                        <b>&nbsp;Set Clear Goals</b>
                                            </ListGroup.Item>
                                        <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1 mb-1 bi-check-circle">
                                        <b>&nbsp;Regularly Engage</b>
                                            </ListGroup.Item>
                                        <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1 mb-1 bi-check-circle">
                                        <b>&nbsp;Experiment with Rephrasing questions</b>
                                            </ListGroup.Item>
                                        <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1 mb-1 bi-check-circle">
                                        <b>&nbsp;Supplement with Human Therapy</b>
                                            </ListGroup.Item>
                                        <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1 mb-1 bi-check-circle">
                                        <b>&nbsp;Be Patient with AI </b>
                                            </ListGroup.Item>
                                        <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1 mb-1 bi-check-circle">
                                        <b>&nbsp;Combine with Self-Care</b>
                                            </ListGroup.Item>
                                        <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1 mb-1 bi-check-circle">
                                        <b>&nbsp;Remember, It's a Tool</b>
                                            </ListGroup.Item>
                                    </ListGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Messages;
