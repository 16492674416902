import GoogleAuth from './googleauth';
import FacebookAuth from './facebookauth';

const Login = () => {
    return (
        <div id='homediv' className="container px-0">
            <div className="container px-3 shadow-lg rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-accent-bg-color border border-3 p-3 psyd-font-accent">
                        Benefits of Signing Up
                    </div>
                    <div className="col">
                    </div>
                    <div className="col">
                    </div>
                </div>
            </div>
            <div className="container px-3">
                <div className="row mt-1 justify-content-center">
                    <div className="col-md-12 p-3 psyd-secondary-bg-color psyd-font shadow-lg rounded">
                        Creating an account on our AI-powered online therapy platform opens the door to a world of support tailored specifically for your needs. With a free account, you’ll receive one complimentary session each month, providing you the opportunity to explore therapy without any financial commitment. This allows you to experience the benefits of professional guidance while deciding if our platform is the right fit for your ongoing mental health journey. Additionally, our premium plans offer enhanced features such as unlimited sessions, personalized therapy plans, and access to specialized resources, all designed to help you thrive emotionally and mentally.
                    </div>
                </div>
            </div>
            <div className="container px-0">
                <div className="container shadow-lg rounded">
                    <div className="row mt-2 mb-0 rounded">
                        <div className="col-md-12 psyd-accent-bg-color border border-3 p-3 psyd-font-accent">
                            Sign up with Google
                        </div>
                        <div className="col">
                        </div>
                        <div className="col">
                        </div>
                    </div>
                </div>
                <div className="row px-0 mt-1 mb-5 text-center">
                    <div class="col">
                        <GoogleAuth />
                    </div>
                </div>
            </div>
            <div className="container px-3">

                <div className="row mt-1 justify-content-center">
                    <div className="col-md-12 p-3 psyd-secondary-bg-color psyd-font shadow-lg rounded">
                        Choosing to sign up using a federated account with Google simplifies your experience even further. By using your Google account, you can quickly and securely access our platform without the hassle of creating and remembering another password. This streamlined process not only saves you time but also enhances your security, as federated accounts leverage Google's robust authentication measures. Plus, you’ll have the convenience of managing all your accounts in one place, making it easier to stay connected with your therapist and track your progress on your mental wellness journey. Join us today and take the first step towards a brighter, healthier future! </div>
                </div>
            </div>
        </div>
    )
}

export default Login;