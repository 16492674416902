// src/components/Layout.js
import React from 'react';
import NavbarMain from './NavbarMain';
import Header from './Header';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';
import '../css/navbar-main.css';


const Layout = ({ menuOptions }) => {
    return (
        <div id="canvas" class="psyd-background-pattern">
            <div id="box_wrapper" class="container pt-1 px-0 shadow-lg rounded psyd-primary-bg-color">
                <div class="container">
                    <Header />
                </div>
                <div class="container psyd-secondary-bg-color ">
                    <NavbarMain menuOptions={ menuOptions }/>
                </div>
                <div className="container outlet px-1">
                    <Outlet />  {/* This will render the child routes */}
                </div>
                <div class="container rounded psyd-secondary-bg-color">
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Layout;
